<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="myButton mr-1"
            to="/"
        >
          <feather-icon
              icon="GridIcon"
              class="mr-50"
          />
          Dashboad
        </b-button>
      </b-col>
      <b-col
          cols="6"
          class="d-flex justify-content-end"
      >
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="myButton mr-1"
            to="/odaTurleri"
        >
          <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50"
          />
          Geri Dön
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="myButton mr-1"
            to="/odaYonetimi"
        >
          <feather-icon
              icon="GridIcon"
              class="mr-50"
          />
          Oda Kayıtları
        </b-button>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-1">
      <b-col cols="8">
        <b-card header-tag="header">
          <template #header>
            <h4>Oda Kayıt Türleri</h4>
          </template>
          <table class="table table-bordered mt-1">
            <thead>
            <tr>
              <th scope="col">
                İSİM
              </th>
              <th scope="col">
                ENTEGRASYON KODU
              </th>
              <th scope="col"/>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in this.$store.getters.odaTurleriList"
                :key="index"
                class="text-center"
            >
              <td>{{ item.adi }}</td>
              <td>{{ item.tip }}</td>
              <td>
                <b-button
                    variant="gradient-primary"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="odaGuncelle(item)"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    variant="gradient-danger"
                    class="btn-icon rounded-circle"
                    size="sm"
                    @click="odaRemove(item)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card header-tag="header">
          <template #header>
            <h4>
              {{
                isEdit == false
                    ? 'Oda Türü Kaydı'
                    : tip + ' Nolu Entegrasyon Kodu Düzenleniyor'
              }}
            </h4>
          </template>
          <b-form-group
              label="Entegrasyon Kodu"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="tip"
                placeholder="Entegrasyon Kodunu Giriniz"
                autocomplete="off"
            />
          </b-form-group>
          <b-form-group
              label="Oda Türü İsmi"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="name"
                placeholder="Oda Türü İsmi"
                @keyup.enter="isEdit == true ? odaUpdate() : odaSave()"
                autocomplete="off"
            />
          </b-form-group>
          <b-row class="odaKayitRow">
            <b-button
                v-show="isEdit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mt-1 mr-1"
                @click="editClosed"
            >
              Vazgeç
            </b-button>
            <b-button
                v-show="isEdit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="odaUpdate"
            >
              Kaydet
            </b-button>
            <b-button
                v-show="!isEdit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="odaSave"
            >
              Kaydet
            </b-button>
          </b-row>
          <b-row :class="{ editOnPrgoress: isEdit }"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isEdit: false,
      tipID: '',
      name: '',
      tip: '',
    }
  },
  computed: {},
  created() {
    this.$store.dispatch('odaTurleri')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearOdaTurleri')
  },
  methods: {
    odaSave() {
      this.$store
          .dispatch('odaTuruKaydi', {
            adi: this.name,
            tip: this.tip,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            (this.name = ''),
                (this.tip = '')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaUpdate() {
      this.$store
          .dispatch('odaTurUpdate', {
            adi: this.name,
            tip: this.tip,
            tipID: this.tipID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.isEdit = false;
            (this.name = ''),
                (this.tip = '')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaGuncelle(item) {
      this.name = item.adi
      this.tip = item.tip
      this.tipID = item.tipID
      this.isEdit = true
    },
    editClosed() {
      this.isEdit = false
      this.name = ''
      this.tip = ''
    },
    odaRemove(val) {
      this.$store
          .dispatch('odaTuruSil', { id: val.tipID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>

<style scoped>
@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 3px !important;
  border: none;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
